
import Vue from "vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";
import { getThemes, setTheme, setDark } from "@/lib/theming";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import { numberPadding } from "@/lib/util";

export default Vue.extend({
  name: "Settings",

  components: { ASwitch, ASelect },

  data() {
    return {
      selectSetor: "",
    };
  },

  computed: {
    regions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      const regions = this.$store.state.regions as {
        id_usuario_empresa: string;
        empresa_id: string;
        regiao_nome: string;
        nome_setor: string;
        id_setor: string;
      }[];

      return regions.map((item) => ({
        ...item,
        text_regiao: `${numberPadding(item.empresa_id)} - ${item.regiao_nome}`,
        text_setor: `${numberPadding(item.id_setor)} - ${item.nome_setor}`,
      }));
    },

    cRegions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      return this.regions.filter((r) => r.id_setor == this.selectSetor);
    },

    setores(): { id: string; text_setor: string }[] {
      return this.regions.map((r) => ({
        id: r.id_setor,
        text_setor: r.text_setor,
      }));
    },

    currentRegionId(): string {
      return this.$store.state.region?.id_usuario_empresa ?? "";
    },

    themes(): Array<string> {
      return getThemes();
    },

    theme(): string {
      return this.$store.state.theme;
    },

    dark(): string {
      return this.$store.state.dark;
    },

    globalLoading: {
      get(): boolean {
        return this.$store.state.globalLoading;
      },
      set(value: boolean) {
        this.$store.commit("globalLoading", value);
      },
    },
  },

  mounted() {
    this.selectSetor =
      this.regions.find((r) => r.id_usuario_empresa == this.currentRegionId)
        ?.id_setor ?? "";
  },

  methods: {
    setTheme,
    setDark,

    async setRegion(id_usuario_empresa: string) {
      try {
        this.globalLoading = true;

        const rq = new Rq("regiao.set", {
          id_usuario_empresa,
        });
        const rspLogin = await Api.request(rq);

        if (rspLogin.ret_id < 1) {
          console.error(rspLogin.ret_msg);
          return;
        }

        const sessionToken = rspLogin.params["session_token"];

        const tblUsuario = rspLogin.getTable("usuario");
        const user = tblUsuario?.getRowObject(0);

        const tblPermissao = rspLogin.getTable("permissao");
        const permissions = tblPermissao?.getRowsObject() ?? [];

        const tblModulo = rspLogin.getTable("modulo");
        const modules = tblModulo?.getRowsObject() ?? [];

        const tblSetor = rspLogin.getTable("setor");
        const section = tblSetor?.getRowObject(0);

        const tblRegioes = rspLogin.getTable("regioes");
        const regions = tblRegioes?.getRowsObject() ?? [];

        const tblRegiao = rspLogin.getTable("regiao");
        const region = tblRegiao?.getRowObject(0);

        this.$store.commit("logout");

        this.$store.commit("login", {
          sessionToken,
          user,
          modules,
          permissions,
          section,
          regions,
          region,
        });

        if (this.$route.name === "worganize") this.$router.go(0);
        else this.$router.push({ name: "worganize" });
      } catch (error) {
        console.error(error);
      } finally {
        this.globalLoading = false;
      }
    },
  },
});
